// @import 'primereact/resources/themes/bootstrap4-dark-blue/theme.css';
// @import 'primereact/resources/themes/md-dark-indigo/theme.css';
// @import 'primereact/resources/themes/mdc-dark-indigo/theme.css';
// @import 'primereact/resources/themes/md-light-indigo/theme.css';
// @import 'primereact/resources/themes/md-light-deeppurple/theme.css';
// @import 'primereact/resources/themes/mdc-light-deeppurple/theme.css';
// @import 'primereact/resources/themes/lara-dark-purple/theme.css';
// @import 'primereact/resources/themes/saga-blue/theme.css';
// @import 'primereact/resources/themes/vela-blue/theme.css';
// @import 'primereact/resources/themes/saga-purple/theme.css';
// @import 'primereact/resources/themes/saga-orange/theme.css';
// @import 'primereact/resources/themes/vela-blue/theme.css';
// @import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
// @import './theme.css';
// @import './themes/pipelines/light/theme.scss';
// @import './themes/pipelines/dark/theme.scss';
@import 'primeicons/primeicons.css';
@import 'primereact/resources/primereact.css';
@import 'primeflex/primeflex.scss';

html {
  font-size: 14px;
  // background-color: var(--surface-b);
}

// button .p-button-label {
//   font-weight: normal;
// }

body {
  @extend .overflow-auto;
  @extend .lg\:overflow-hidden;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', */
  /* 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', */
  /* sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // overflow: hidden;

}

#root {
  height: 100vh;
  width: 100vw;
}

a {
  text-decoration: none;
}

a:not(.p-menuitem-link,.p-menuitem-text,.p-button):hover, .p-button-text:hover .p-button-label {
  text-decoration: underline;
}

.p-menuitem-link *, .p-menuitem-link:hover *, .p-button-icon, .p-button-icon:hover{
  text-decoration: none!important;
}

.p-avatar {
  min-width: 2rem;
}


.hover-show {
  opacity: 0;
  transition: 250ms;
}
.hover-show:hover {
  opacity: 100;
  transition: 250ms;
}

.text-sparse {
  letter-spacing: .1rem;
}

.font-black {
  font-weight: 900;
}

// #side-menu {
//   button, div {
//     box-shadow: none;
//   }
//   .p-tree-header{
//     padding-left: var(--content-padding);
//     padding-right: var(--content-padding);
//     padding-top: calc(var(--content-padding) / 2);
//   }
//   .p-tree .p-treenode.p-treenode-leaf button.p-tree-toggler {
//     // display: none;
//     width: 0;
//   }
//   .p-treenode-content .p-treenode-label {
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//     .p-highlight .add-button{
//       color: var(--surface-0)
//     }
//   }
//   .p-highlight .add-button{
//     color: var(--surface-0);
//   }
// }

g.react-flow__edge-buttonEdge{
  .edgebutton-foreignobject {
    display: none;
  }
  &:hover{
    .edgebutton-foreignobject {
      display: block;
    }
  }
}

.react-flow.cursor-auto .react-flow__pane {
  cursor: auto;
}

.p-0 .p-overlaypanel-content {
  padding: 0!important;
}

.multiselect-no-checkbox {
  .p-multiselect-trigger, .p-checkbox {
    display: none;
  }
}

.dropdown-hide-disabled li.p-disabled,
.inline-dropdown .p-dropdown-trigger {
  display: none;
}

.inline-multiselect .p-multiselect-label, 
.inline-dropdown .p-dropdown-label {
  padding: 0!important;
}

.inline-multiselect .p-multiselect-trigger {
  width:0;
}


.p-avatar-group .p-multiselect-label {
  display:flex
}

.centered-card {
  li.p-tabmenuitem {
    @extend .shadow-2;
    .p-highlight .p-menuitem-link{
      @extend .border-none
    }
  }
}

.help-text-panel{
  background-color: var(--yellow-100);
  &::after {
    border-bottom-color: var(--yellow-100);
  }
  &.p-overlaypanel-flipped::after {
    border-top-color: var(--yellow-100);
  }
}

.markdown {
  table, th, td{
    border-collapse: collapse;
    border: 1px solid var(--surface-border);
  }
  th,td{
    padding: 0.1rem;
    font-size: 0.85rem;
    @media screen and (min-width: 992px) {
      font-size: 1em;
      padding: 0.5rem;
    }
  }
  i.fas, i.far, i.pi, i.pplns {
    color: var(--primary-color);
  }
}

#side-menu #side-menu-lock{
  display: none;
}
#side-menu:hover #side-menu-lock{
  display: flex;
}

$flowPanelIndex: 0.75;
.flow-panel.small-device button.p-button {
  height: calc(2.5rem * $flowPanelIndex ) !important;
  width: calc(2.5rem * $flowPanelIndex ) !important;
}