@font-face {
    font-family: "pipelines-icons";
    src: url("./pipelines-icons.eot?b523b25d58dfc105dddf529550a78daf#iefix") format("embedded-opentype"),
url("./pipelines-icons.woff2?b523b25d58dfc105dddf529550a78daf") format("woff2"),
url("./pipelines-icons.woff?b523b25d58dfc105dddf529550a78daf") format("woff");
}

[class^="pplns-"], [class*=" pplns-"] {
    font-family: pipelines-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.pplns-pipeline:before {
    content: "\f101";
}
.pplns-pipelines-thin:before {
    content: "\f102";
}
.pplns-pipelines:before {
    content: "\f103";
}
